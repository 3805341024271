import Keycloak from 'keycloak-js'
import { requireNotNull } from '../utils'
import { useEffect, useMemo, useState, type PropsWithChildren } from 'react'

import { createContext, useContext } from 'react'
import { useLocalStorage } from 'shared/hooks/useLocalStorage'

export type KcStatuses = 'pending' | 'success' | 'error'

export const KC_LOCALSTORAGE_REFRESH_TOKEN_KEY = 'cc_crm_kc_refreshToken'
export const KC_LOCALSTORAGE_TOKEN_KEY = 'cc_crm_kc_token'

export const kcCtx = createContext<{ kcStatus: KcStatuses; authenticated: boolean }>({
	kcStatus: 'pending',
	authenticated: false,
})

export const useKcCtx = () => useContext(kcCtx)


export const keycloak = new Keycloak({
	realm: requireNotNull(window.__env__.KEYCLOAK_REALM),
	clientId: requireNotNull(window.__env__.KEYCLOAK_CLIENT_ID),
	url: requireNotNull(window.__env__.KEYCLOAK_URL),
})

let isInitialized = false
export const KeycloakProvider = ({ children }: PropsWithChildren<unknown>) => {
	const [status, setStatus] = useState<KcStatuses>('pending')
	const [authenticated, setAuthenticated] = useState(false)
	const [refreshToken, setRefreshToken] = useLocalStorage<string | undefined>(
		KC_LOCALSTORAGE_REFRESH_TOKEN_KEY,
		undefined
	)
	const [token, setToken] = useLocalStorage<string | undefined>(
		KC_LOCALSTORAGE_TOKEN_KEY,
		undefined
	)
	const value = useMemo(() => ({ kcStatus: status, authenticated }), [authenticated, status])

	keycloak.onAuthRefreshSuccess = () => {
		setToken(keycloak.token)
		setRefreshToken(keycloak.refreshToken)
	}

	useEffect(() => {
		const init = async () => {
			isInitialized = true

			try {
				await keycloak.init({
					onLoad: 'login-required',
					refreshToken,
					token,
					pkceMethod: 'S256',
					redirectUri: location.href,
					checkLoginIframe: false,
				})

				setToken(keycloak.token)
				setRefreshToken(keycloak.refreshToken)

				setAuthenticated(true)
				setStatus('success')
			} catch {
				setStatus('error')
			}
		}

		if (!keycloak.authenticated && !isInitialized) init()
	}, [refreshToken, setRefreshToken, setToken, token])

	if (!authenticated || status === 'pending') return <div>loading...</div>
	if (status === 'error') throw new Error()

	return <kcCtx.Provider value={value}>{children}</kcCtx.Provider>
}
