import { Wrapper, TopMenu, Menu, Logout } from './styled'
import { keycloak } from 'app/keycloak'

export function UserInfo() {
	return (
		<Wrapper>
			<TopMenu>
				<Menu>
					Вы авторизованы как: {keycloak.tokenParsed?.preferred_username || ''}
					<Logout onClick={() => keycloak.logout()}>Выйти</Logout>
				</Menu>
			</TopMenu>
		</Wrapper>
	)
}
